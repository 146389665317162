import { graphql } from "gatsby";
import React from "react";
import { StringParam, useQueryParam } from "use-query-params";
import Results from "../components/results";
import { normalizeArticle } from "../helper/normalizer";
import { getValue } from "../helper/translation";
import Layout from "../modules/layout";
import News from "../modules/news";
import SEO from "../modules/seo";

const Search = ({
  data: {
    articles,
    publications,
    allContentfulFocusOfWork,
    allContentfulOrganization,
    allContentfulTopic,
    allContentfulRegion,
    allContentfulArticleType,
  },
  pageContext,
}) => {
  const { microcopy, node_locale } = pageContext.settings;
  const showNews = pageContext.showNews;
  const siteSettings = pageContext.settings;
  const topics = allContentfulTopic.edges.map(edge => edge.node);
  const focusesOfWork = allContentfulFocusOfWork.edges.map(edge => edge.node);
  const organizations = allContentfulOrganization.edges.map(edge => edge.node);
  const regions = allContentfulRegion.edges.map(edge => edge.node);
  const articleTypes = publications?.edges.length
    ? allContentfulArticleType.edges
        .map(edge => edge.node)
        .concat({
          id: getValue("labels.publications", microcopy).toLowerCase(),
          contentful_id: "publikationen",
          name: getValue("labels.publications", microcopy),
        })
    : allContentfulArticleType.edges.map(edge => edge.node);

  const tagQueryParam = useQueryParam("tag", StringParam)[0];

  const items = articles.edges
    .map(edge => ({
      ...normalizeArticle(edge.node.articleDetail),
      generalTitle: edge.node.title,
    }))
    .concat(
      publications.edges.map(edge => ({
        ...edge.node.publicationDetail,
        generalTitle: edge.node.title,
        typeOfArticle: {
          id: getValue("labels.publications", microcopy).toLowerCase(),
          name: getValue("labels.publications", microcopy),
        },
      }))
    );

  return (
    <Layout siteSettings={siteSettings} pageContext={pageContext}>
      <SEO
        title={`${siteSettings.siteName} - ${getValue(
          "search.searchResults",
          microcopy
        )}`}
        description={siteSettings.siteDescription}
        author={siteSettings.siteAuthor}
      />
      <div className="articleOverview pt-4">
        <Results
          type="search"
          articleTypes={articleTypes}
          topics={topics}
          focusesOfWork={focusesOfWork}
          organizations={organizations}
          regions={regions}
          items={items}
          microcopy={microcopy}
          tag={tagQueryParam}
          locale={node_locale}
        />
      </div>
      <News
        settings={siteSettings}
        node_locale={node_locale}
        visible={showNews}
      ></News>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($node_locale: String!) {
    articles: allContentfulArticleGeneral(
      filter: { articleDetail: { node_locale: { eq: $node_locale } } }
    ) {
      totalCount
      edges {
        node {
          title
          articleDetail {
            id
            node_locale
            imageSquare {
              imageSquare {
                fluid {
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                  aspectRatio
                }
              }
            }
            articleReader {
              json
            }
            articleContent {
              json
            }
            articleTitle
            articleSubtitle
            visibleTags
            organization {
              id
              name
            }
            topic {
              id
              name
            }
            focusOfWork {
              id
              name
            }
            region {
              id
              name
            }
            postDateTime
            authorOfPublication {
              contentful_id
              id
              name
            }
            typeOfArticle {
              id
              name
            }
          }
        }
      }
    }
    publications: allContentfulPublicationGeneral(
      filter: { publicationDetail: { node_locale: { eq: $node_locale } } }
    ) {
      edges {
        node {
          title
          publicationDetail {
            id
            visibleTags
            node_locale
            title
            document {
              title
              file {
                url
              }
            }
            content {
              json
            }
            focusOfWork {
              id
              name
            }
            organizations {
              id
              name
            }
            publicationDateTime
            topic {
              id
              name
            }
            region {
              id
              name
            }
            imagePortrait {
              imagePortrait {
                fluid {
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                  aspectRatio
                }
              }
            }
            reader {
              json
            }
            author {
              contentful_id
              id
              name
            }
          }
        }
      }
    }
    allContentfulFocusOfWork(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          id
          contentful_id
          name
        }
      }
    }
    allContentfulOrganization(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          id
          contentful_id
          name
        }
      }
    }
    allContentfulTopic(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          id
          contentful_id
          name
        }
      }
    }
    allContentfulRegion(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          id
          contentful_id
          name
        }
      }
    }
    allContentfulArticleType(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          id
          contentful_id
          name
        }
      }
    }
  }
`;
export default Search;
